// Heavily inspired by https://github.com/github/file-attachment-element

<template>
  <div class="u-w-full">
    <input
      ref="file"
      type="file"
      :multiple="multiple"
      class="hidden"
    >
    <div
      ref="dropzone"
      class="file-upload"
      :class="{
        'file-upload--control form-control': !isArea,
        'file-upload--area': isArea,
        'file-upload--hover': isHovering,
        'file-upload--start': !isUploading && !attachments,
      }"
      @click.prevent="selectFiles(true)"
    >
      <slot>
        <template v-if="isUploading">
          <spinner small class="u-mr-xx-small" />
          Uploading...
        </template>
        <div v-else-if="attachments" class="u-flex-row u-flex-child u-align-center">
          <div class="text-center u-flex-child">
            {{ attachmentsDisplay }}
          </div>
          <i
            aria-hidden="true"
            class="fas fa-times-circle icon-button icon-button--muted"
            @click.prevent.stop="clear"
          />
        </div>
        <template v-else>
          {{ multiple ? 'Drag & drop or select files' : 'Drag & drop or select file' }}
        </template>
      </slot>
      <div v-if="isArea" class="file-upload__area-toolbar" @click="selectFiles(false)">
        <template v-if="isUploading">
          <spinner small class="u-mr-xx-small" />
          Uploading...
        </template>
        <div v-else-if="error" class="text-red" v-text="error" />
        <template v-else>
          Attach images by dragging & dropping or selecting them
        </template>
      </div>
    </div>
    <div v-if="!isArea && error" :class="{'has-error': error}">
      <span class="help-block">{{ error }}</span>
    </div>
  </div>
</template>

<script>
import ImageService from '../../services/ImageService'

export default {
  props: {
    isArea: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    onlyTransform: {
      type: Boolean,
      default: false,
    },
    imageServiceOptions: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isHovering: false,
      isUploading: false,
      attachments: null,
      error: null,
    }
  },

  computed: {
    attachmentsDisplay() {
      const display = this.attachments.map(attachment => attachment.name).join(', ')
      if (display.length < 40) {
        return display
      }

      return this.attachments.length + ' files uploaded'
    },
  },

  mounted() {
    const dropzone = this.$refs.dropzone
    dropzone.addEventListener('dragenter', this.onDragEnter)
    dropzone.addEventListener('dragover', this.onDragEnter)
    dropzone.addEventListener('dragleave', this.onDragLeave)
    dropzone.addEventListener('drop', this.onDrop)

    const fileInput = this.$refs.file
    fileInput.addEventListener('change', this.onChange)
  },

  methods: {
    selectFiles(checkArea) {
      if (checkArea && this.isArea) {
        return
      }

      const fileInput = this.$refs.file
      fileInput.click()
    },

    clear() {
      this.attachments = null
      this.error = null
    },

    onDragEnter(event) {
      const transfer = event.dataTransfer
      if (!transfer || !this.hasFile(transfer)) {
        return
      }

      this.isHovering = true
      transfer.dropEffect = 'copy'

      event.stopPropagation()
      event.preventDefault()
    },

    onDragLeave(event) {
      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = 'none'
      }

      this.isHovering = false
      event.stopPropagation()
      event.preventDefault()
    },

    onDrop(event) {
      this.isHovering = false

      const transfer = event.dataTransfer
      if (!transfer || !this.hasFile(transfer)) {
        return
      }

      this.processFiles(transfer.files)

      event.stopPropagation()
      event.preventDefault()
    },

    onChange(event) {
      const fileInput = event.target
      const files = fileInput.files

      if (!files || files.length === 0) {
        return
      }

      this.processFiles(files)
    },

    processFiles(files) {
      this.attachments = null
      this.error = null

      if (!this.canProcessFiles(files)) {
        return
      }

      this.isUploading = true

      const promises = this.onlyTransform
        ? ImageService.transformToBase64(files, this.imageServiceOptions)
        : ImageService.upload(files, this.imageServiceOptions)
      Promise.all(promises)
        .then(attachments => {
          this.attachments = attachments
          this.handleAttachments(attachments)
        })
        .catch(error => {
          this.error = error
          this.attachments = null
        })
        .finally(() => {
          this.isUploading = false
          this.$refs.file.value = ''
        })
    },

    handleAttachments(attachments) {
      const payload = this.multiple ? attachments : attachments[0]
      this.$emit('attachmentsUploaded', payload)
    },

    canProcessFiles(files) {
      if (!this.multiple && files.length > 1) {
        this.error = 'Only 1 file can be selected'
      }

      return !this.error
    },

    hasFile(transfer) {
      return Array.from(transfer.types).indexOf('Files') >= 0
    },
  },
}
</script>
