<template>
  <div
    class="simple-bar-chart__bar"
    :class="{'simple-bar-chart__bar--empty': width === 0}"
    :style="{'background-color': barColor, width: width + '%' }"
  />
</template>

<script>
import getRGBProperty from '../../functions/getRGBProperty'

export default {
  props: ['width', 'index'],

  computed: {
    barColor() {
      if (!this.width) {
        return null
      }

      const palette = [
        'emerald',
        'brink-pink',
        'jasmine',
        'little-boy-blue',
      ]

      return `rgb(${getRGBProperty(palette[this.index % palette.length])})`
    },
  },
}
</script>
