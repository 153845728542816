import { convertToIANATimezone } from '@qlik-trial/automations-ui'

const getters = {
  getUser: () => {
    return window.user
  },

  providerSettings: () => {
    return window.provider
      ? window.provider.settings || {}
      : {}
  },

  getFeatures: () => {
    return {}
  },

  timeZone: () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return convertToIANATimezone(timezone)
  },

  realtimeApiUrl: () => {
    return window.realtimeApiUrl
  },

  apiUrl: () => {
    return window.apiUrl
  },
}

const actions = {
  fetchUser({ getters }) {
    return getters.getUser
  },
}

export default {
  getters,
  actions,
}
