<template>
  <div class="breadcrumbs-container">
    <ol class="breadcrumb">
      <li class="breadcrumb__item">
        <a class="muted-link" href="/">Home</a>
      </li>
      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        class="breadcrumb__item"
        :class="{ 'active': index === breadcrumbs.length - 1 }"
      >
        <span v-if="index === breadcrumbs.length - 1" v-text="breadcrumb.name" />
        <a
          v-else
          :href="breadcrumb.url || '#'"
          class="muted-link"
          @click="handleClick($event, breadcrumb)"
          v-text="breadcrumb.name"
        />
      </li>
    </ol>
  </div>
</template>

<script>
import { EventBroker } from '@qlik-trial/automations-ui'

export default {
  props: [
    'initialBreadcrumbs',
  ],

  data() {
    return {
      breadcrumbs: Object.entries(this.initialBreadcrumbs)
        .map(([url, name]) => {
          return {
            url,
            name,
          }
        }),
    }
  },

  mounted() {
    EventBroker.subscribe('SET_BREADCRUMBS', this.setBreadcrumbs)
  },

  beforeDestroy() {
    EventBroker.unsubscribe('SET_BREADCRUMBS', this.setBreadcrumbs)
  },

  methods: {
    handleClick(event, breadcrumb) {
      if (breadcrumb.click) {
        event.preventDefault()
        breadcrumb.click()
      }
    },

    setBreadcrumbs(breadcrumbs) {
      this.breadcrumbs = breadcrumbs
    },
  },
}
</script>
