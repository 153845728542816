function registerToggle() {
  const activeMenuItem = document.querySelector('.js-menu .active')

  if (!activeMenuItem) {
    return
  }

  activeMenuItem.addEventListener('click',
    function (event) {

      if (window.innerWidth <= 850) {
        event.preventDefault()

        const menu = document.querySelector('.js-menu ul')
        menu.classList.toggle('expanded')
      }
    },
    { capture: true })
}

window.addEventListener('load', () => {
  registerToggle()
})
