<template>
  <div class="panel datasource-overview">
    <div class="datasource-overview__heading">
      <search
        v-model="query"
        placeholder="Search for connector"
        variant="secondary"
        size="large"
        @input="handleSearchInput"
      />
    </div>
    <div class="datasource-overview__menu">
      <div v-if="showCategories">
        <h5 class="menu-title">
          Category
        </h5>
        <ul class="nav">
          <li
            role="presentation"
            class="menu-item"
            :class="{'menu-item--active': category == null}"
            @click.prevent="setCategory(null)"
          >
            All
          </li>
          <li
            v-for="datasourceTypegroup in datasourceTypeGroups"
            role="presentation"
            class="menu-item"
            :class="{'menu-item--active': category === datasourceTypegroup.id}"
            @click.prevent="setCategory(datasourceTypegroup.id)"
          >
            {{ datasourceTypegroup.name }}
          </li>
        </ul>
      </div>
    </div>
    <integrations-list
      :link-closure="linkClosure"
      :datasource-types="queriedDatasourceTypes"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import Fuse from 'fuse.js'

export default {
  props: [
    'datasourceTypes',
    'datasourceTypeGroups',
  ],

  data() {
    return {
      query: null,
      category: null,
      fuse: null,
      queriedDatasourceTypes: [],
      linkClosure: function (datasourceType) {
        return '/integrations/' + datasourceType.slug
      },
    }
  },

  computed: {
    showCategories() {
      return this.datasourceTypeGroups && this.datasourceTypeGroups.length > 1
    },

    filteredDatasourceTypes() {
      if (this.category === null) {
        return this.datasourceTypes
      }

      return this.datasourceTypes.filter(datasourceType => datasourceType.datasourcetypegroup_id === this.category)
    },
  },

  created() {
    this.queriedDatasourceTypes = this.filteredDatasourceTypes
    this.setupFuse()
  },

  methods: {
    setupFuse() {
      this.fuse = new Fuse(this.filteredDatasourceTypes, { threshold: 0.35, keys: ['name'] })
    },

    handleSearchInput: debounce(function () {
      if (!this.query || this.query.length === 0) {
        this.queriedDatasourceTypes = this.filteredDatasourceTypes

        return
      }

      this.queriedDatasourceTypes = this.fuse.search(this.query)
        .map(result => result.item)
    }, 100),

    setCategory(id) {
      this.category = id
      this.setupFuse()
      this.queriedDatasourceTypes = this.filteredDatasourceTypes
      this.query = null
    },
  },
}
</script>
