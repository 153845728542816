import Vue from 'vue'
import Vuex from 'vuex'
import { modules } from '@qlik-trial/automations-ui'
import {
  formulas,
  endpoints,
  objectTypes,
  pointers,
  unifiedApi,
  widgetRunRouter,
  widgets,
  states,
  datasourceTypes,
  datasourceConnector,
  logos,
  undo,
} from '@qlik-trial/automations-editor/store'
import icons from './modules/icons'
import tags from './modules/tags'
import meta from './modules/meta'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...modules,
    formulas,
    endpoints,
    objectTypes,
    pointers,
    unifiedApi,
    widgetRunRouter,
    widgets,
    states,
    datasourceTypes,
    datasourceConnector,
    logos,
    undo,
    meta,
    icons,
    tags,
  },
})
