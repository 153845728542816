<template>
  <div v-click-outside="closeDropdown" class="u-relative">
    <label>Tags</label>
    <custom-select
      :value="tags"
      multiple
      can-be-cleared
      empty-placeholder="No tags"
      class="tagsbar__custom-select"
      @input="handleSelect($event)"
    >
      <template v-slot:default="{ option }">
        <tag :tag="option.tag" :force-active="true" />
      </template>

      <!--Last option, add "Manage tags" button-->
      <template v-slot:extra>
        <hr class="u-m-none u-mb-xx-small">

        <a href="/tags" class="tags-picker-action muted-link u-ml-medium">
          <div class="tags-picker-icon">
            <i class="far fa-cog" />
          </div>
          Manage tags
        </a>
      </template>
    </custom-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dropdown: false,
    }
  },

  computed: {
    tags() {
      const allTags = this.$store.getters.getTags

      const formattedTags = allTags.map(tag => {
        return ({
          label: tag.name,
          value: tag.id,
          selected: tag.active,
          tag: tag,
        })
      })

      return formattedTags
    },

    activeTags() {
      return this.$store.getters.getActiveTags
    },
  },

  methods: {
    handleSelect(options) {
      const user = this.$store.getters.getUser

      const toggledOptions = options.filter((option, index) =>
        option.tag.active !== this.tags[index].selected
      )

      toggledOptions.forEach(option => {
        const tag = option.tag
        this.$store.commit('toggleTag', { tag, user })
      })
    },

    closeDropdown() {
      this.dropdown = false
    },
  },
}
</script>

<style lang="scss">
  .tagsbar__custom-select .custom-select {
    height: 46px;
  }

  .tags-picker-action{
    padding: 6px 12px;
    display: block;
    cursor: pointer;
  }

  .tags-picker-icon{
    display: inline-block;
    width: 28px;
    margin-left: -6px;
    text-align: center;
  }
</style>
