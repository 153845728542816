<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-distance="300"
    infinite-scroll-throttle-delay="50"
    class="datasource-overview__tiles datasource-overview__tiles--integrations"
  >
    <a
      v-for="datasourceType in displayDatasourceTypes"
      class="datasourcetype-card datasourcetype-card--integration list-item"
      :href="linkClosure(datasourceType)"
    >
      <div class="datasourcetype-card__logo" :style="'background-image: url(' + datasourceType.logo_large + ');'" />
      <h4 class="datasourcetype-card__name u-mt-large">
        {{ datasourceType.name }}
      </h4>
    </a>
  </div>
</template>
<script>
const AMOUNT_ITEMS_INCREMENT = 18

export default {
  props: [
    'datasourceTypes',
    'linkClosure',
  ],

  data() {
    return {
      maxItems: AMOUNT_ITEMS_INCREMENT,
      infiniteId: 1,
    }
  },

  computed: {
    displayDatasourceTypes() {
      return this.datasourceTypes.slice(0, this.maxItems)
    },
  },

  watch: {
    datasourceTypes() {
      this.maxItems = AMOUNT_ITEMS_INCREMENT
    },
  },

  methods: {
    loadMore() {
      this.maxItems += AMOUNT_ITEMS_INCREMENT
    },
  },
}
</script>
