import { axios } from '@qlik-trial/automations-ui'
import Attachment from './Attachment'

class ImageService {
  attachments
  options

  static transformToBase64(files, options) {
    const imageService = new ImageService(files, options)
    const onload = (event, attachment, resolve, _reject) => {
      attachment.data = event.target.result
      resolve(attachment)
    }

    return imageService.transform(onload)
  }

  static upload(files, options) {
    const imageService = new ImageService(files, options)
    const onload = (event, attachment, resolve, reject) =>
      imageService.uploadData(event, attachment, resolve, reject)

    return imageService.transform(onload)
  }

  uploadData(event, attachment, resolve, reject) {
    attachment.data = event.target.result

    axios.post(this.options.url, { image: attachment.data })
      .then(response => {
        attachment.url = response.data.url

        resolve(attachment)
      })
      .catch(error => {
        attachment.error = error

        reject(attachment)
      })
  }

  constructor(files, options) {
    this.attachments = Array.from(files || []).map(file => new Attachment(file))
    this.options = Object.assign({
      url: '/images',
    }, options)
  }

  transform(onload) {
    return this.attachments.map(attachment =>
      new Promise((resolve, reject) => {
        const error = ImageService.isAttachmentInvalid(attachment)
        if (error) {
          reject(error)
        }

        const reader = new FileReader()
        reader.onload = event => onload(event, attachment, resolve, reject)
        reader.readAsDataURL(attachment.file)
      }),
    )
  }

  static isAttachmentInvalid(attachment) {
    const types = ['image/png', 'image/jpeg']
    if (!types.includes(attachment.type)) {
      return 'Only png and jpegs are supported'
    }

    if (attachment.size > 2000) { // todo: make generic with options
      return 'Images have to be smaller than 2MB'
    }
  }
}

export default ImageService
