<template>
  <div class="form-group">
    <label v-text="label" />
    <div
      class="u-relative"
      aria-haspopup="true"
      tabindex="0"
      style="outline: none"
      @blur="closeMenu"
      @focus="openMenu"
    >
      <div
        class="u-cursor-clickable u-flex-row form-control form-control-large"
        :class="{'multi-dropdown-active': showMenu, 'multi-dropdown-readonly': readonly}"
        @click="openMenu"
      >
        <div class="u-flex-child" v-text="activeOption && activeOption.title" />
        <div class="u-flex u-justify-center u-align-center">
          <i class="far fa-angle-down" aria-hidden="true" />
        </div>
      </div>
      <ul v-show="showMenu" class="multi-dropdown">
        <li v-for="option in options" @click.stop="setOption(option)">
          <b v-text="option.title">Inactive</b>
          <p v-html="option.description" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['label', 'options', 'value', 'readonly'],

  data() {
    return {
      showMenu: false,
    }
  },

  computed: {
    activeOption() {
      return this.options.find(o => o.id === this.value)
    },
  },

  methods: {
    openMenu() {
      if (this.readonly) {
        return
      }

      this.showMenu = true
    },

    closeMenu() {
      this.showMenu = false
    },

    setOption(option) {
      this.$emit('input', option.id)
      this.closeMenu()
    },
  },
}
</script>

<style lang="scss">
  .multi-dropdown {
    border: 1px solid #ccc;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 0;
    background: var(--color-white);
    z-index: 500;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;
  }

  .multi-dropdown > li {
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
  }

  .multi-dropdown > li:hover {
    cursor: pointer;
    color: #536c8f;
    background: #e6e5e5;
  }

  .multi-dropdown > li > p {
    margin: 10px 0 0 10px;
  }

  .multi-dropdown-active{
    border-color: #66afe9 !important;
    outline: 0 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
  }

  .multi-dropdown-readonly{
    background-color: #eee!important;
    opacity: 1 !important;
  }
</style>
