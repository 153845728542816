<template>
  <div class="panel container-medium">
    <h2 class="bar-chart-title">
      {{ __('blend') }} runs
    </h2>
    <select class="bar-chart-dropdown" @change="loadChart">
      <option v-for="option in options" :value="option.date" v-text="option.label" />
    </select>
    <div class="bar-chart">
      <div class="first-row">
        <div class="legend-y">
          <div v-for="value in valuesY" v-text="value" />
        </div>
        <div class="bars">
          <div v-for="item in items" :title="item.label + ' - ' + item.count + ` ${__('blend')} runs`">
            <div :style="'height: ' + ( item.count / max * 100 ) + '%'" />
          </div>
        </div>
      </div>
      <div class="legend-x">
        <div v-for="date in dates" v-text="date" />
      </div>
    </div>
  </div>
</template>

<script>
import setDate from 'date-fns/setDate'
import differenceInMonths from 'date-fns/differenceInMonths'
import getDaysInMonth from 'date-fns/getDaysInMonth'
import subMonths from 'date-fns/subMonths'
import subDays from 'date-fns/subDays'
import format from 'date-fns/format'
import getUnixTime from 'date-fns/getUnixTime'
import fromUnixTime from 'date-fns/fromUnixTime'
import isSameDay from 'date-fns/isSameDay'
import { axios } from '@qlik-trial/automations-ui'
import { parseDate } from '@qlik-trial/automations-ui'

export default {
  data() {
    return {
      firstRecord: null,
      items: [],
    }
  },

  computed: {
    max() {
      return Math.round(Math.max(...this.items.map(d => d.count)) * 1.1)
    },

    valuesY() {
      const min = 0
      const max = this.max
      const middle = Math.round(this.max / 2)

      return [
        max, middle, min,
      ]
    },

    dates() {
      if (!this.items.length) {
        return
      }
      const dates = [
        this.items[0].date,
        this.items[15].date,
        this.items[this.items.length - 1].date,
      ]

      return dates.map(d => format(d, 'MMMM d'))
    },

    options() {
      if (!this.firstRecord) {
        return
      }

      const endOfMonth = setDate(new Date(), 31)
      const monthDifference = differenceInMonths(endOfMonth, this.firstRecord)
      const months = Math.max(Math.min(monthDifference + 1, 12), 1)

      const today = setDate(new Date(), getDaysInMonth(new Date()) + 1)

      const options = []

      for (let a = 0; a < months; a++) {
        const date = subMonths(today, a + 1)

        options.push({
          date: getUnixTime(date),
          label: format(date, 'MMMM yyyy'),
        })
      }

      return options
    },
  },

  mounted() {
    this.loadChart()

    const date = new Date()
    const today = setDate(date, getDaysInMonth(date))
    for (let a = 30; a >= 0; a--) {
      this.items.push({
        date: subDays(today, a),
        count: 0,
      })
    }
  },

  methods: {
    loadChart(e) {
      const value = e && e.target.value

      axios.get('/widgets/chart' + (value ? '?from=' + value : '')).then(response => {
        this.firstRecord = parseDate(response.data.firstRecord)
        this.items = []

        const dateForToday = value
          ? fromUnixTime(value)
          : new Date()

        const today = setDate(dateForToday, getDaysInMonth(dateForToday))

        for (let a = (getDaysInMonth(today) - 1); a >= 0; a--) {
          const currentDate = subDays(today, a)
          this.items.push({
            date: currentDate,
            label: format(currentDate, 'MMMM d'),
            count: (response.data.data.find(data => {
              return isSameDay(parseDate(data.date), currentDate)
            }) || {}).count || 0,
          })
        }
      },
      )
    },
  },
}
</script>
