<template>
  <div class="color-picker u-flex-row u-align-center">
    <div
      class="color-picker__display form-control u-cursor-clickable"
      :class="{'form-control--focus': isOpen}"
      :style="'background-color:' + hex + ';'"
      @click="isOpen = true"
    />
    <a
      v-show="value"
      href="#"
      class="muted-link u-ml-medium u-text-small"
      @click="clear"
    >
      Clear
    </a>

    <div v-if="isOpen" v-click-outside="close" class="dropdown color-picker__dropdown">
      <label :for="'hex-' + id">
        Hex
      </label>
      <input
        :id="'hex-' + id"
        v-model="hex"
        type="text"
        class="form-control"
      >

      <div class="u-flex-row u-space-between u-mt-medium">
        <div>
          <label :for="'r-' + id">
            R
          </label>
          <input
            :id="'r-' + id"
            v-model="r"
            type="number"
            min="0"
            max="255"
            class="form-control"
          >
        </div>
        <div class="u-ml-x-small">
          <label :for="'g-' + id">
            G
          </label>
          <input
            :id="'g-' + id"
            v-model="g"
            type="number"
            min="0"
            max="255"
            class="form-control"
          >
        </div>
        <div class="u-ml-x-small">
          <label :for="'b-' + id">
            B
          </label>
          <input
            :id="'b-' + id"
            v-model="b"
            type="number"
            min="0"
            max="255"
            class="form-control"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uuidv4, hexToRGB } from '@qlik-trial/automations-ui'
import rgbToHex from '../../functions/rgbToHex'

export default {
  props: [
    'value',
    'default',
  ],

  data() {
    return {
      id: uuidv4(),
      isOpen: false,
    }
  },

  computed: {
    rgb() {
      if (this.value) {
        return this.value.split(',')
      } else if (this.default) {
        return this.default.split(',')
      }

      return ['', '', '']
    },

    r: {
      get: function () {
        return parseInt(this.rgb[0], 10)
      },
      set: function (newValue) {
        this.handleColor([newValue, this.g, this.b])
      },
    },

    g: {
      get: function () {
        return parseInt(this.rgb[1], 10)
      },
      set: function (newValue) {
        this.handleColor([this.r, newValue, this.b])
      },
    },

    b: {
      get: function () {
        return parseInt(this.rgb[2], 10)
      },
      set: function (newValue) {
        this.handleColor([this.r, this.g, newValue])
      },
    },

    hex: {
      get: function () {
        return rgbToHex(this.r, this.g, this.b)
      },
      set: function (newValue) {
        const startsWithHash = newValue.slice(0, 1) === '#'

        if (startsWithHash && newValue.length === 7) {
          const rgb = hexToRGB(newValue)
          this.handleColor(rgb)
        } else if (newValue.length === 6) {
          const hexWithHash = '#' + newValue
          const rgb = hexToRGB(hexWithHash)
          this.handleColor(rgb)
        }
      },
    },
  },

  methods: {
    handleColor(rgb) {
      rgb = rgb.map(byte => this.parseByte(byte))
      if (rgb.every(byte => !isNaN(byte))) {
        this.$emit('input', `${rgb[0]},${rgb[1]},${rgb[2]}`)
      }
    },

    parseByte(byte) {
      const parsed = parseInt(byte, 10)

      if (parsed < 0) {
        return 0
      } else if (parsed > 255) {
        return 255
      }

      return parsed
    },

    clear() {
      this.$emit('input', undefined)
    },

    close() {
      this.isOpen = false
    },
  },
}
</script>
