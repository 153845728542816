<template>
  <div class="panel">
    <h2>
      {{ widget.name }}
      {{ widget.status !== 'live' ? ' - ' + titleCase(widget.status) : null }}
    </h2>

    <div class="u-flex-row u-justify-center u-mt-xxx-large">
      <div class="u-mr-x-large">
        <div class="widget-template-icons">
          <img :src="widgetIcons.icon">
          <img v-if="widgetIcons.icon3" :src="widgetIcons.icon3">
        </div>

        <button class="btn btn--primary  btn-full-width" :disabled="isDisabled" @click.prevent="useTemplate">
          <i v-if="isLoading" class="fas fa-spinner fa-spin" aria-hidden="true" />
          Use Integration
        </button>
      </div>
      <div style="flex: 1">
        <div v-if="isFetching">
          <i class="fas fa-spinner fa-spin" aria-hidden="true" />
        </div>
        <markdown v-else :value="widget.description || widget.short_description" class="text-break" />
      </div>
    </div>
    <div v-if="error" class="alert alert--danger">
      <i class="fas fa-exclamation-circle" aria-hidden="true" />
      {{ error.error || error }}
      <div v-if="error && error.widget" class="u-mt-small">
        <a :href="'/widgets/' + error.widget.guid">Go to integration</a>
      </div>
    </div>
  </div>
</template>

<script>
import { axios } from '@qlik-trial/automations-ui'
import widgetIcons from '../../mixins/widgetIcons'
import { mapGetters } from 'vuex'

export default {
  mixins: [widgetIcons],

  props: {
    widget: Object,
    isFetching: Boolean,
  },

  data() {
    return {
      isLoading: false,
      error: null,
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
    ]),

    isDisabled() {
      if (this.isLoading) {
        return true
      }

      if (!this.getUser) {
        return true
      }

      if (['superadmin', 'poweradmin'].includes(this.getUser.role)) {
        return false
      }

      if (this.widget.status === 'live') {
        return false
      }

      return true
    },
  },

  methods: {
    useTemplate() {
      if (this.isLoading) {
        return
      }

      if (!this.getUser) {
        return window.location.href = '/login?redirect_url=/templates/' + this.widget.guid
      }

      this.isLoading = true
      this.error = null

      axios.post('/templates/' + this.widget.guid + '/install')
        .then(response => {
          window.location.href = response.data.redirect
        })
        .catch(error => {
          this.error = error.response.data
        })
        .finally(() => this.isLoading = false)
    },
  },
}
</script>

<style lang="scss">
  .widget-template-detail {
    background: var(--color-white);
  }

  .widget-template-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-bottom: 4em;
  }

  .widget-template-icons img {
    display: block;
    height: 75px;
    margin-left: 5px;
    margin-right: 5px;
  }
</style>
