import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

function shouldBeIgnored(hint) {
  if (!hint || !hint.originalException) {
    return false
  }

  const nameException = [
    'VALIDATION_EXCEPTION',
  ].includes(hint.originalException.name)

  const messageException = [
    'NetworkError: Failed to execute \'send\' on \'XMLHttpRequest\': Failed to load',
  ].includes(hint.originalException.message)

  return nameException || messageException
}

export default function () {
  if (!window.sentry.environment) {
    return
  }

  Sentry.init({
    Vue,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'Non-Error promise rejection captured',
    ],
    beforeSend: function (event, hint) {
      if (shouldBeIgnored(hint)) {
        return null
      }

      return event
    },
    dsn: process.env.DSN,
    environment: window.sentry.environment,
    release: window.sentry.release,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  })

  window.Sentry = Sentry.getCurrentHub()

  if (window.user) {
    Sentry.configureScope(scope => {
      scope.setUser({
        user_id: window.user.id,
        account_guid: window.user.account.guid,
      })
    })
  }
}
