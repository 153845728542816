<template>
  <div>
    <div v-if="step && step.showHeader !== false" class="wz-header">
      <template v-for="(cStep, index) in headerSteps">
        <div class="wz-step" :class="{'wz-step--active': cStep.id === step.id}">
          <h3 class="wz-step-number" :class="{'wz-step-number--active': cStep.id === step.id}" v-text="index + 1" />
          <h5 class="wz-step-title" v-text="cStep.title" />
          <div v-if="cStep.subtitle" class="wz-step-subtitle" v-text="cStep.subtitle" />
        </div>
        <div v-if="index !== headerSteps.length - 1" class="wz-step-dots" />
      </template>
    </div>

    <div class="panel">
      <div v-for="(s, index) in steps">
        <slot v-if="activeStep === index" :name="'step-' + s.id" />
      </div>
    </div>

    <div v-if="step && step.showFooter !== false" class="u-flex-row u-align-center u-space-between">
      <div v-if="showPrevious" class="u-clickable" @click.prevent="goPrevious">
        <i class="far fa-angle-left u-mr-xx-small" aria-hidden="true" /> Back
      </div>

      <div class="u-ml-auto">
        <button
          v-for="button in step.buttons || []"
          class="u-mr-small"
          :class="button.class || 'btn btn--primary'"
          :disabled="button.disabled"
          @click.prevent="button.action"
        >
          <i v-if="button.loading" class="fas fa-spinner fa-spin fa-fw u-mr-xx-small" />
          <span v-html="button.text" />
          <i
            v-if="step.buttons.length === 0"
            class="far fa-angle-right u-ml-xx-small"
            aria-hidden="true"
          />
        </button>

        <button
          v-if="showNext"
          ref="next"
          class="btn btn--primary"
          :disabled="loading || (step.next && step.next.disabled)"
          @click.prevent="goNext"
        >
          <i v-if="loading" class="fas fa-spinner fa-spin fa-fw u-mr-xx-small" />
          {{ step.next && step.next.text || $t('NEXT') }}
          <i
            class="far u-ml-xx-small"
            :class="step.next && step.next.icon || 'fa-angle-right'"
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: Array,
  },

  data() {
    return {
      activeStep: 0,
      loading: false,
    }
  },

  computed: {
    showPrevious() {
      return this.activeStep !== 0 && this.step.showPrevious !== false
    },

    showNext() {
      return this.activeStep < this.steps.length - 1 && this.step.showNext !== false
    },

    step() {
      return this.steps[this.activeStep]
    },

    headerSteps() {
      return this.steps.filter(step => {
        return step.showInHeader !== false
      })
    },
  },

  watch: {
    step: {
      immediate: true,
      handler: function (step, oldStep = {}) {
        if (step && step.mounted && step.id !== oldStep.id) {
          step.mounted()
        }

        this.$emit('update:active-step', this.step)
      },
    },
  },

  methods: {
    goTo(id) {
      this.activeStep = this.steps.findIndex(step => step.id === id)
    },

    goNext() {
      this.loading = true;

      (this.step.next && this.step.next.action && this.step.next.action() || Promise.resolve())
        .then(shouldContinue => {
          if (shouldContinue !== false) {
            this.activeStep++
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    goPrevious() {
      this.activeStep--
    },
  },
}
</script>

<style lang="scss">
  .wz-header {
    display: flex;
    justify-content: center;
    padding: 30px;
  }

  .wz-step {
    position: relative;
  }

  .wz-step-number {
    background: var(--color-silver-chalice);
    color: var(--color-text--inverse);
    width: 50px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto auto 25px;
  }

  .wz-step-number--active {
    background: var(--color-primary);
  }

  .wz-step-title {
    text-align: center;
    width: 200px;
  }

  .wz-step-dots {
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;
    width: 150px;
    border-top: 1px dashed var(--color-silver-chalice);
  }
</style>
