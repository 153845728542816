// Because of CSP
__webpack_nonce__ = window.nonce

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import '../sass/app.scss'

// Logging of errors
import initSentry from './analytics/initSentry'

initSentry()

// Enable real-time messages
import initSocketIO from './functions/initSocketIO'

initSocketIO()

import Vue from 'vue'
import autofocus from 'vue-autofocus-directive'
import vClickOutside from 'v-click-outside'
import infiniteScroll from 'vue-infinite-scroll'
import VueLocalStorage from 'vue-localstorage'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import 'focus-visible'

// Admin
import AdminComponents from './AdminComponents.js'
import ProviderComponents from './ProviderComponents.js'
import { registerStore } from '@qlik-trial/automations-ui'
import store from './store'
import merge from 'lodash/merge'

registerStore(store)

// Store
import { widget } from '@qlik-trial/automations-editor/store'

Vue.use(VueRouter)
Vue.use(VueLocalStorage)
Vue.use(vClickOutside)
Vue.use(VueI18n)
Vue.use(infiniteScroll)

Vue.directive('autofocus', autofocus)

require('./menu-dropdown')
require('./components')


if (window.user) {
  if (window.user.role === 'superadmin' || window.user.role === 'poweradmin' || window.user.role === 'support') {
    Vue.use(AdminComponents)
  } else if (window.user.isAdminProvider) {
    Vue.use(ProviderComponents)
  }
}

import(
  /* webpackChunkName: "lang-[request]" */
  `./lang/${window.language}.json`)
  .then(messages => {
    const i18n = new VueI18n({ locale: window.language, messages })

    new Vue({
      el: '#app',
      store,
      i18n,

      data() {
        return {
          headerNavbar: false,
          env: process.env.NODE_ENV,
          fullHeight: null,
          contentWidth: null,
          initialData: window.initialData,
        }
      },

      computed: {
        hash() {
          return window.decodeURI(window.location.hash.substr(1))
        },

        isFullHeight() {
          return this.fullHeight instanceof Function ? this.fullHeight() : this.fullHeight
        },

        style() {
          return this.isFullHeight ? 'height: 100%' : ''
        },

        modal() {
          return this.$store.getters.modal
        },
      },

      watch: {
        modal(newModal, _oldModal) {
          if (newModal) {
            document.body.classList.add('u-overflow-hidden')
          } else {
            document.body.classList.remove('u-overflow-hidden')
          }
        },
      },

      beforeCreate() {
        window.waVm = this

        if (window.state && window.state.widget) {
          if (!window.state.widgets) {
            window.state.widgets = { widgets: {} }
          }

          window.state.widgets.widgets[window.state.widget.guid] = window.state.widget
          window.state.widgets.activeWidgetGuid = window.state.widget.guid

          delete window.state.widget
        }

        if (window.state && window.state.widgets && window.state.widgets.activeWidgetGuid) {
          this.$store.registerModule('widget', widget)
        }

        this.$store.replaceState(merge({}, this.$store.state, window.state))
      },

      created() {
        const user = this.$store.getters.getUser
        if (user) {
          this.$store.commit('initActiveTags', { user })
        }
        this.$store.dispatch('setupTranslator')
      },

      methods: {
        hideHeaderNavbar() {
          this.headerNavbar = false
        },
      },
    })
  })
