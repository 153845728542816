<!-- This (temporary) component is a replacement for all native html buttons which perform a delete action. They require an DELETE request, including a CSRF token, instead of an anchor tag -->
<template>
  <a href="#" :title="title" @click="confirm">
    <form
      ref="form"
      :action="path"
      style="visibility: hidden; position: absolute"
      method="post"
      @submit.stop
    >
      <input type="hidden" name="_method" value="DELETE">
      <input name="_token" type="hidden" :value="token">
    </form>
    <i aria-hidden="true" class="far fa-trash icon-button icon-button--danger" />
  </a>
</template>

<script>
export default {
  props: ['path', 'title'],

  computed: {
    token() {
      return window.Laravel.csrfToken
    },
  },

  methods: {
    confirm() {
      if (confirm('Are you sure?')) {
        this.$refs.form.submit()
      }
    },
  },
}
</script>

