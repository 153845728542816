export default {
  install(Vue) {
    [

      // Provider components
      'accounts-index',
      'accounts-create',
      'account-detail',
      'provider-edit',
      'widget-templates-index',
      'folder-index',
      'provider-datasourcetypes',
    ].forEach(name => {
      Vue.component(name, resolve => {
          import(/* webpackChunkName: "provider" */ './components/admin/provider-loader').then(loadAdminComponents => {
            resolve(loadAdminComponents.default().find(i => i.name === name).component)
          })
      })
    },
    )
  },
}