<template>
  <div v-if="metrics" class="container--metrics">
    <div class="metrics-grid">
      <metric
        v-if="'accounts' in metrics"
        label="Accounts"
        :value="formatNumber(metrics.accounts)"
      />

      <metric
        label="Users"
        :value="formatNumber(metrics.users)"
      />

      <metric
        :label="blendName"
        :value="formatNumber(metrics.widgets)"
        :info-label="'The amount of ' + blendName + ' in this account.'"
      />

      <metric
        label="Active connectors"
        :value="formatNumber(metrics.connected_datasources)"
        info-label="The amount of connected connections in this account."
      />
    </div>
    <h3>
      Yesterday
    </h3>
    <div class="metrics-grid metrics-grid--with-big">
      <metric
        label="Job runs yesterday"
        :value="formatNumber(metrics.job_runs_yesterday)"
        value-label="total"
        info-label="Other job runs can be test runs, settings, ..."
        class="metric--big-first"
      >
        <simple-bar-chart :data="jobRunsData" />
      </metric>

      <metric
        label="Job duration"
        :value="jobDuration"
        value-label="mins"
        info-label="Sum of all job run execution time"
        class="metric--small-fix-span"
      />

      <metric
        label="Average concurrent jobs"
        :value="averageConcurrentJobs"
        info-label="Average amount of jobs running at the same time"
        class="metric--small-fix-span"
      />

      <metric
        v-if="received"
        label="Data received"
        :value="received.value"
        :value-label="received.unit"
        class="metric--small-fix-span"
      >
        <div class="metric__icon">
          <i aria-hidden="true" class="far fa-arrow-alt-circle-down" />
        </div>
      </metric>

      <metric
        v-if="transmitted"
        label="Data transmitted"
        :value="transmitted.value"
        :value-label="transmitted.unit"
        class="metric--small-fix-span"
      >
        <div class="metric__icon">
          <i aria-hidden="true" class="far fa-arrow-alt-circle-up" />
        </div>
      </metric>
    </div>
  </div>
  <spinner v-else>
    Loading metrics...
  </spinner>
</template>

<script>
import { axios, formatBytes } from '@qlik-trial/automations-ui'
import SimpleBarChart from './SimpleBarChart'
import { MessageLevel } from '@qlik-trial/automations-ui'

export default {
  components: { SimpleBarChart },

  props: ['url'],

  data() {
    return {
      metrics: null,
      blendName: this.__('blend', true),
    }
  },

  computed: {
    jobRunsData() {
      let otherRuns
      if (!this.metrics.job_runs_yesterday) {
        otherRuns = 0
      } else {
        otherRuns = this.metrics.job_runs_yesterday
          - (this.metrics.job_scheduled_yesterday)
          - (this.metrics.job_webhook_yesterday)
          - (this.metrics.job_triggered_yesterday)
          - (this.metrics.job_realtime_triggered_yesterday)
      }

      return [
        { label: 'scheduled', value: this.metrics.job_scheduled_yesterday },
        { label: 'triggered', value: this.metrics.job_triggered_yesterday },
        { label: 'realtime triggered', value: this.metrics.job_realtime_triggered_yesterday },
        { label: 'webhooks', value: this.metrics.job_webhook_yesterday },
        { label: 'others', value: otherRuns },
      ]
    },

    averageConcurrentJobs() {
      if (this.metrics.average_concurrent_jobs < 0.01 && this.metrics.average_concurrent_jobs > 0.0) {
        return '< 0.01'
      }

      return this.metrics.average_concurrent_jobs ? this.metrics.average_concurrent_jobs.toFixed(2) : 0.0
    },

    jobDuration() {
      return this.metrics.job_total_duration ? (this.metrics.job_total_duration / 60.0).toFixed(2) : 0.0
    },

    received() {
      if (!this.metrics.job_total_rx_bytes) {
        return null
      }

      return formatBytes(this.metrics.job_total_rx_bytes)
    },

    transmitted() {
      if (!this.metrics.job_total_tx_bytes) {
        return null
      }

      return formatBytes(this.metrics.job_total_tx_bytes)
    },
  },

  created() {
    this.fetch()
  },

  methods: {
    fetch() {
      axios
        .get(this.url)
        .then(response => {
          this.metrics = this.sanitizeMetrics(response.data)
        })
        .catch(_error => {
          this.metrics = {}
          this.flash({ message: 'An error occurred while fetching metrics', level: MessageLevel.DANGER })
        })
    },

    formatNumber(number) {
      return !isNaN(number) ? (number).toLocaleString('en') : 0
    },

    sanitizeMetrics(metrics) {
      for (const metric in metrics) {
        if (!metrics[metric]) {
          metrics[metric] = 0
        }
      }

      return metrics
    },
  },
}
</script>

