<template>
  <tabs :tabs="tabs" class="panel container-medium" :options="options" />
</template>

<script>
import JobMonitor from './JobMonitor'

export default {

  data() {
    return {
      options: {
        rememberPosition: false,
      },

      tabs: [
        { id: 'running', title: 'Running ' + this.__('blend', true), component: JobMonitor, props: { status: 'running' } },
        { id: 'finished', title: 'Finished ' + this.__('blend', true), component: JobMonitor, props: { status: 'finished' } },
        { id: 'finished with warnings', title: 'Warning ' + this.__('blend', true), component: JobMonitor, props: { status: 'finished with warnings' } },
        { id: 'failed', title: 'Failed ' + this.__('blend', true), component: JobMonitor, props: { status: 'failed' } },
      ],
    }
  },
}
</script>
