import Vue from 'vue'

import WidgetChart from './components/widgets/WidgetChart'
import WidgetTemplates from './components/widgets/WidgetTemplates'
import WidgetListItem from './components/widgets/WidgetListItem'
import IntegrationsList from './components/integrations/IntegrationsList'
import Integrations from './components/integrations/Integrations'
import MyHubAndTemplatesPage from './pages/MyHubAndTemplatesPage'
import JobLogs from './components/jobs/JobLogs'
import Wizard from './components/Wizard'
import MarkdownEditor from './components/MarkdownEditor'
import Breadcrumbs from './components/basic/Breadcrumbs'
import ColorPicker from './components/basic/ColorPicker'
import FileUpload from './components/basic/FileUpload'
import BladeFormWrapper from './components/basic/BladeFormWrapper'
import WidgetsIndex from './components/widgets/WidgetsIndex'
import InputImage from './components/form/InputImage'
import MultiDropdown from './components/form/MultiDropdown'
import ButtonDelete from './components/ButtonDelete'
import Metrics from './components/metrics/Metrics'

import Components from '@qlik-trial/automations-ui'
import EditorComponents from '@qlik-trial/automations-editor/plugin'

Vue.use(Components)
Vue.use(EditorComponents)

// Pages
Vue.component('my-hub-and-templates-page', MyHubAndTemplatesPage)
Vue.component('cdp-page', () => import(/* webpackChunkName: "cdp-page" */'./pages/cdp/CDPRouterPage'))
Vue.component('empty-homepage', () => import(/* webpackChunkName: "empty-homepage" */'./pages/EmptyHomepage'))

// Components
Vue.component('license', () => import(/* webpackChunkName: "license" */'./components/account/License'))
Vue.component('model-deleted', () => import(/* webpackChunkName: "model-deleted" */'./components/ModelDeleted'))
Vue.component('model-not-allowed', () => import(/* webpackChunkName: "model-not-allowed" */'./components/ModelNotAllowed'))

Vue.component('job-logs', JobLogs)
Vue.component('wizard', Wizard)
Vue.component('markdown-editor', MarkdownEditor)
Vue.component('breadcrumbs', Breadcrumbs)
Vue.component('color-picker', ColorPicker)
Vue.component('file-upload', FileUpload)
Vue.component('blade-form-wrapper', BladeFormWrapper)

Vue.component('input-image', InputImage)
Vue.component('multi-dropdown', MultiDropdown)
Vue.component('button-delete', ButtonDelete)

Vue.component('metrics', Metrics)

// Widgets
Vue.component('widgets-index', WidgetsIndex)
Vue.component('widget-chart', WidgetChart)
Vue.component('widget-templates', WidgetTemplates)
Vue.component('widget-list-item', WidgetListItem)

Vue.component('widget-detail-core-container', () => import(/* webpackChunkName: "widget-detail" */'@qlik-trial/automations-editor/components/WidgetDetailCoreContainer'))
Vue.component('widget-editor', () => import(/* webpackChunkName: "widget-editor" */'@qlik-trial/automations-editor/components/WidgetEditor'))
Vue.component('widget-run', () => import(/* webpackChunkName: "widget-run" */'@qlik-trial/automations-editor/components/WidgetRun'))
Vue.component('widget-setup', () => import(/* webpackChunkName: "widget-setup" */'./components/widgets/WidgetSetup'))
Vue.component('widget-settings', () => import(/* webpackChunkName: "widget-settings" */'./components/widgets/WidgetSettings'))
Vue.component('widget-simple-detail', () => import(/* webpackChunkName: "widget-simple-detail" */'./components/widgets/WidgetSimpleDetail'))
Vue.component('widget-run-page', () => import(/* webpackChunkName: "widget-run-page" */'./components/widgets/WidgetRunPage'))

// Datasources
Vue.component('datasource-invite', () => import(/* webpackChunkName: "datasourceInvite" */'./components/datasources/DatasourceInvite'))
Vue.component('all-datasources-index', () => import(/* webpackChunkName: "datasources-overview-index" */'./components/datasourcetypes/DatasourcesOverviewIndex'))

// Integrations
Vue.component('integrations', Integrations)
Vue.component('integrations-list', IntegrationsList)

// Auth
Vue.component('register-form', () => import(/* webpackChunkName: "auth"*/'./components/auth/RegisterForm'))
Vue.component('login-form', () => import(/* webpackChunkName: "auth"*/'./components/auth/LoginForm'))
Vue.component('register-invite', () => import(/* webpackChunkName: "auth"*/'./components/auth/RegisterInvite'))
Vue.component('reset-password-form', () => import(/* webpackChunkName: "auth"*/'./components/auth/ResetPasswordForm'))
Vue.component('change-password-form', () => import(/* webpackChunkName: "auth"*/'./components/auth/ChangePasswordForm'))
Vue.component('two-fa-form', () => import(/* webpackChunkName: "auth"*/'./components/auth/TwoFAForm'))
Vue.component('two-fa-connect', () => import(/* webpackChunkName: "auth"*/'./components/auth/TwoFaConnect'))
Vue.component('auth-settings', () => import(/* webpackChunkName: "auth"*/ './components/auth/AuthSettings'))
Vue.component('auth-provider-settings', () => import(/* webpackChunkName: "auth"*/ './components/auth/AuthProviderSettings'))
Vue.component('agree-terms', () => import(/* webpackChunkName: "auth"*/ './components/AgreeTerms'))
Vue.component('recent-devices', () => import(/* webpackChunkName: "auth"*/ './components/RecentDevices'))
Vue.component('sso-login', () => import(/* webpackChunkName: "auth"*/ './components/auth/SsoLogin'))
Vue.component('helpdesk', () => import(/* webpackChunkName: "auth"*/ './components/account/Helpdesk'))

// Tags
Vue.component('tags-index', () => import(/* webpackChunkName: "tags"*/ './components/TagsIndex'))
