export default {
  computed: {
    widgetIcons() {
      if (!this.widget) {
        return {}
      }
      
      if (window.provider.datasourcetype_logo === this.widget.icon && this.widget.icon3) {
        return {
          icon: this.widget.icon3,
        }

      } else if (window.provider.datasourcetype_logo === this.widget.icon3 && this.widget.icon) {
        return {
          icon: this.widget.icon,
        }
      } else {
        return {
          icon: this.widget.icon,
          icon3: this.widget.icon3,
        }
      }
    },
  },
}