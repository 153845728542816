<template>
  <div>
    <file-upload
      is-area
      multiple
      @attachmentsUploaded="handleAttachments"
    >
      <code-mirror
        ref="codeMirrorElement"
        v-model="internalValue"
        language="markdown"
        :name="name"
        class="code-mirror--file-upload"
      >
        <template slot="preview" slot-scope="{ fullscreen, value }">
          <div v-if="fullscreen" style="padding: 10px;background:white;border-left: 1px solid #dddddd;" v-html="md(internalValue)" />
        </template>
      </code-mirror>
    </file-upload>
  </div>
</template>

<script>
import { createMarkdownInstance } from '@qlik-trial/automations-ui'
import getMarkdownImages from '../functions/getMarkdownImages'

const md = createMarkdownInstance()

export default {
  props: { name: String, options: Object, value: String },

  data() {
    return {
      internalValue: this.value,
    }
  },

  methods: {
    md(data) {
      return md.render(data)
    },

    handleAttachments(attachments) {
      const markdownImages = getMarkdownImages(attachments)
      let doc
      if (this.$refs.codeMirrorElement) {
        doc = this.$refs.codeMirrorElement.codeMirror.getDoc()
      }

      if (!markdownImages || !doc) {
        return
      }

      markdownImages.forEach(markdownImage => {
        doc.replaceSelection(markdownImage)
      })
    },
  },
}
</script>
