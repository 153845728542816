<template>
  <a
    :href="link ? link : '/widgets/' + widget.guid"
    class="widget-list-item"
  >
    <div class="panel panel--compact u-mb-small">
      <div class="u-flex-row">
        <div class="widget-list-icons">
          <img :src="widgetIcons.icon">
          <img v-if="widgetIcons.icon3" :src="widgetIcons.icon3">
        </div>
        <div class="widget-list-body">
          <div class="widget-list-body__text">
            <div class="u-flex u-flex-row u-align-baseline u-flex-nowrap">
              <h5 class="widget-list-item__title u-mb-xxx-small u-mt-xxx-small">
                {{ widget.name }}
              </h5>
              <span
                v-if="widget.short_description"
                class="widget-list-description u-truncate"
                :title="widget.short_description"
                v-text="widget.short_description"
              />
            </div>
            <p class="text-muted u-mb-0">
              <span v-if="widget.last_run_at">
                Last run at
                <date :value="widget.last_run_at" />
              </span>
              <span v-else>Has not yet run</span>
            </p>
          </div>
          <div class="widget-list-body__tags">
            <widget-icons v-if="!isSimpleMode" :widget="widget" class="u-flex-row u-align-center" />
            <i class="far fa-angle-right widget-list-item__caret u-ml-large u-mr-small" :title="'View details'" />
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import widgetIcons from '../../mixins/widgetIcons'

export default {
  mixins: [widgetIcons],

  props: {
    widget: Object,
    link: String,
  },

  computed: {
    isSimpleMode() {
      return window.provider.settings.datablends.simpleMode
    },

    showTags() {
      return !this.isSimpleMode
    },
  },
}
</script>

<style lang="scss">
 .widget-list-item {
   display: block;
   position: relative;
   cursor: pointer;
   font-weight: 400;
   font-size: 16px;
   color: inherit;
   transition: color 100ms, box-shadow 100ms;
 }

  .widget-list-item:hover {
    color: inherit;
    box-shadow: var(--shadow-medium);
    transition: box-shadow 100ms;
  }

  .widget-list-item:hover .widget-list-item__title {
    color: var(--color-primary);
    transition: color 100ms;
  }

  .widget-list-item:hover .widget-list-item__caret {
    color: var(--color-primary);
    transition: color 100ms;
  }

  .widget-list-icons {
    width: 135px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .widget-list-icons img {
    max-height: 36px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .widget-list-description {
    word-break: break-word;
    max-width: 180px;
    max-height: 40px;
    margin-bottom: 0;
    margin-left: 8px;
    font-size: var(--font-size-small);
  }

  .widget-list-body {
    flex: 1;
    max-height: 45px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .widget-list-body__text {
    display: flex;
    flex-direction: column;
  }

  .widget-list-body__tags {
    display: flex;
    align-items: center;
  }

  .widget-list-actions {
    margin-left: 2em;
    margin-right: 2em;
    display: flex;
    align-items: center;

  }

  .widget-list-item__caret {
    display: flex;
    align-items: center;
    font-size: 3rem;
  }
</style>
