<template>
  <div>
    <input
      ref="fileDialog"
      type="file"
      class="hidden"
      :accept="accept"
      @change="fileDialogChange"
    >
    <div
      id="filedrag"
      class="input-image-container"
      @drop.prevent="drop"
      @click.prevent="openFileDialog"
    >
      <i
        v-if="canDeleteImage"
        class="far fa-times"
        aria-hidden="true"
        @click.stop="deleteImage"
      />
      <img v-if="image || value" :src="image || value">
      <i v-else class="far fa-upload" aria-hidden="true" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    accept: {
      type: String,
      default: 'mage/png,image/jpeg',
    },
  },

  data() {
    return {
      originalValue: this.value,
      image: null,
    }
  },

  computed: {
    canDeleteImage() {
      return this.image && this.image !== this.originalValue
    },
  },

  watch: {
    value: function (value) {
      this.image = value
    },

    image: function (value) {
      this.$emit('input', value)
    },
  },

  methods: {
    openFileDialog() {
      this.$refs.fileDialog.click()
    },

    fileDialogChange() {
      this.setImage(this.$refs.fileDialog.files[0])
    },

    drop(e) {
      e.preventDefault()

      this.setImage(e.dataTransfer.files)
    },

    setImage(file) {
      if (!file) {
        return
      }
      const reader = new FileReader()
      reader.onload = e => {
        this.image = e.target.result
        this.$refs.fileDialog.value = null
      }
      reader.readAsDataURL(file)
    },

    deleteImage() {
      this.image = null
      this.$emit('input', this.originalValue)
    },
  },
}
</script>

<style lang="scss">
  .input-image-container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 1px dashed #898989;
    color: #898989;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
  }

  .input-image-container:hover {
    color: #898989;
    border: 2px dashed #898989;
  }

  .input-image-container > img {
    max-height: 100%;
    max-width: 100%;
    padding: 5%;
  }

  .input-image-container > .fa-times {
    position: absolute;
    top: -5px;
    right: -5px;
    color: var(--color-danger);
  }

  .input-image-container:hover > .fa-times {
    top: -6px;
    right: -6px;
  }
</style>
