<template>
  <a :href="link ? link : '/templates/' + template.guid" class="template-card" @click="clickHandler">
    <div class="template-card-icons" :style="{ backgroundColor: color }">
      <div>
        <img :src="template.icon" class="template-card-logo" :class="{'template-card-icon--offset': isComingSoon}">
      </div>

      <div v-if="template.icon3">
        <img :src="template.icon3" class="template-card-logo" :class="{'template-card-icon--offset': isComingSoon}">
      </div>

      <div v-if="isComingSoon" class="template-card__status" :class="{'template-card__status--dark': hasDarkBackground}">
        Coming soon
      </div>
    </div>
    <div class="template-card-bottom" :class="{'flex u-justify-center': !template.short_description}">
      <h5 class="template-card__name" v-text="template.name" />
      <p v-if="template.short_description" v-text="template.short_description" />
    </div>
  </a>
</template>

<script>
import { computeLuminance } from '@qlik-trial/automations-ui'

export default {

  props: {
    template: Object,
    showStartIcons: {
      type: Boolean,
      default: false,
    },
    click: Function,
    link: String,
  },

  computed: {
    color() {
      const templateColor = this.template.template_color

      return templateColor ? '#' + templateColor : null
    },

    isComingSoon() {
      return this.template.status === 'coming_soon'
    },

    backgroundLuminance() {
      if (this.color) {
        return computeLuminance(this.color)
      }

      return 1
    },

    hasDarkBackground() {
      return this.backgroundLuminance < 0.5
    },
  },

  methods: {
    clickHandler(event) {
      if (this.click) {
        event.preventDefault()
        this.click(this.template)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .template-card {
    width: calc(1 / 3 * 100% - 40px) !important;
    margin-right: 40px !important;
    margin-bottom: 40px !important;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    background: var(--color-white);
    border-radius: var(--radius-large);
    border: 1px solid var(--color-concrete);
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    box-shadow: var(--shadow-default);
  }

  .template-card:hover {
    .template-card__name, .template-card-bottom p {
      color: var(--color-link);
    }
  }

  .template-card-icons {
    position: relative;
    background-color: var(--color-background--secondary);
    display: flex;
    height: 120px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .template-card-icon--offset {
    margin-bottom: var(--spacing-large);
  }

  .template-card-logo{
    max-height: 60px;
  }

  .template-card-icons > div{
    padding: 10px;
    border-radius: 4px;
  }

  .template-card-bottom{
    padding: 10px;
    height: 130px;
    min-height: 130px;
    max-height: 130px;
    overflow: hidden;
  }

  .template-card__name {
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }

  .template-card-bottom p {
    color: var(--color-text);
    max-width: 100%;
    font-size: var(--font-size-small);
    overflow: auto;
    max-height: 70px;
    text-align: center;
    padding: 0 var(--spacing-x-small);
  }

  .template-card__status {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: var(--spacing-xxx-small);
    text-transform: uppercase;
    font-size: var(--font-size-small);
    font-weight: 500;
    color: var(--color-text);
  }

  .template-card__status--dark {
    color: var(--color-text--inverse);
  }
</style>
