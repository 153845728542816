export default function (r, g, b) {
  return '#' + byteToHex(r) + byteToHex(g) + byteToHex(b)
}

function byteToHex(byte) {
  const hex = byte.toString(16)

  if (hex.length === 1) {
    return '0' + hex
  }

  return hex
}
