<template>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-10 col-md-offset-1 text-center">
        <h1>My Hub and Templates</h1>
        <p class="fs-16 u-mb-medium u-mt-medium">
          SaaS companies can create templates for customers, and publish them in their Hub.
          Please <a href="https://www.blendr.io/contact/">contact us</a>
          if you are a SaaS company and you would like to enable this feature.
          Please note that this feature is enabled after attending a short training sessions.
        </p>
        <p>
          <a
            class="btn btn--primary"
            href="https://help.blendr.io/docs/getting-started-for-saas-companies"
          >
            More info
          </a>
        </p>
      </div>
    </div>
    <div class="row u-mt-medium hidden-xs">
      <div class="col-xs-12">
        <img src="/img/background_home.svg" class="u-w-full">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyHubAndTemplatesPage',
}
</script>
