<template>
  <table class="table">
    <thead>
      <tr>
        <th style="min-width: 190px">
          Status
        </th>
        <th style="width: 99%">
          Name
        </th>
        <th style="min-width: 150px">
          Time Started
        </th>
        <th style="min-width: 150px">
          Duration
        </th>
      </tr>
    </thead>
    <tbody is="transition-group" :name="loaded ? 'list' : ''">
      <tr v-for="job in jobs" :key="job.guid">
        <td v-html="statusFormatter(job)" />
        <td>
          <div class="text-overflow" style="max-width: 560px">
            <a :href="'/widgets/' + job.widget.guid + '/history/' + job.guid" v-text="job.widget.name" />
          </div>
        </td>
        <td>
          <date :value="job.starttime" />
        </td>
        <td v-html="durationFormatter(job)" />
      </tr>
      <tr v-if="Object.keys(jobs).length === 0 && loaded" key="empty">
        <td colspan="3">
          Currently no {{ __('blend', true) }} to show.
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import differenceInSeconds from 'date-fns/differenceInSeconds'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInDays from 'date-fns/differenceInDays'
import { axios, parseDate } from '@qlik-trial/automations-ui'
import formatJobStatus from '@qlik-trial/automations-editor/editor/jobs/formatJobStatus'

export default {
  props: [
    'status',
  ],

  data() {
    return {
      jobs: {},
      timeout: null,
      loaded: false,
    }
  },

  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  },

  created() {
    this.loadJobs()
      .then(() => this.loaded = true)
  },

  methods: {
    loadJobs() {
      return axios.get('/jobs', {
        params: {
          status: this.status,
        },
      })
        .then(response => {
          if (response.data && Array.isArray(response.data)) {
            response.data.forEach(this.setJob)

            this.removeOldJobs(response.data.map(j => j.guid))

            this.timeout = setTimeout(this.loadJobs, 3000)
          }
        })
    },

    setJob(job) {
      this.$set(this.jobs, job.guid, job)

      if (['finished', 'failed', 'stopped', 'finished with warnings'].includes(job.status) && this.status === 'running') {
        setTimeout(() => {
          this.$delete(this.jobs, job.guid)
        }, 5000)
      }
    },

    removeOldJobs(guids) {
      Object.keys(this.jobs)
        .filter(guid => !guids.includes(guid))
        .forEach(guid => {
          setTimeout(() => {
            this.$delete(this.jobs, guid)
          }, 1000)
        })

    },

    statusFormatter(job) {
      return formatJobStatus(job)
    },

    durationFormatter(job) {
      if (!job.starttime) {
        return ''
      }

      const stopTime = job.stoptime ? parseDate(job.stoptime) : new Date()
      const startTime = parseDate(job.starttime)

      const secondsDiff = differenceInSeconds(stopTime, startTime)

      if (secondsDiff === 0) {
        return '< 1 second'
      }

      if (secondsDiff < 120) {
        return secondsDiff + ' seconds'
      }

      const minutesDiff = differenceInMinutes(stopTime, startTime)
      if (minutesDiff < 120) {
        return minutesDiff + ' minutes'
      }

      const hoursDiff = differenceInHours(stopTime, startTime)
      if (hoursDiff < 48) {
        return hoursDiff + ' hours'
      }

      return differenceInDays(stopTime, startTime) + ' days'
    },
  },
}
</script>

<style lang="scss">
  .list-enter-active, .list-leave-active {
    transition: all .7s;
  }

  .list-enter {
    opacity: 0;
    transform: translateY(30px);
  }

  .list-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }
</style>
