export default {
  install(Vue) {
    Vue.component('admin-webhooks', () => import(/* webpackChunkName: "admin-webhooks" */'./components/admin/webhooks/AdminWebhooks'));

    [
      'widget-template-form',
      'conformity-list',
      'providers-index',
      'provider-widget-groups-edit',
      'job-metrics',
      'widget-used-in-list',
      'lookup-tool',
      'endpoint-advanced',
      'datasource-type-advanced',
      'admin-datasource-types-index',
      'snippets-index',
      'data-import',
      'admin-exports',
      'licenses-list',

      // Provider components
      'accounts-index',
      'accounts-create',
      'account-detail',
      'provider-edit',
      'widget-templates-index',
      'folder-index',
      'provider-datasourcetypes',

      // Custom components
      'input-param',
      'custom-code-documentation',

      'datasource-type-logo',
      'agents',
      'admin-apps',
    ].forEach(name => {
      Vue.component(name, resolve => {
          import(/* webpackChunkName: "admin" */ './components/admin/admin-loader').then(loadAdminComponents => {
            resolve(loadAdminComponents.default().find(i => i.name === name).component)
          })
      })
    },
    )
  },
}
