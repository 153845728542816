<template>
  <div class="u-flex u-align-center">
    <!-- Header -->
    <div v-if="header" class="wt-header" v-html="header" />

    <!-- Main -->
    <div class="container-medium u-flex-row">
      <!-- Left Menu-->
      <div v-if="folder.folders.length > 1" class="hidden-xs wt-menu">
        <h5>
          Category
        </h5>
        <ul>
          <li
            class="menu-item"
            :class="{'menu-item--active': folderId === null}"
            @click="setFolder(null)"
          >
            All
          </li>

          <li
            v-for="subFolder in folder.folders"
            :key="subFolder.id"
            class="menu-item"
            :class="{'menu-item--active': folderId === subFolder.id}"
            @click.prevent="setFolder(subFolder.id)"
            v-text="subFolder.name"
          />
        </ul>
      </div>

      <!-- Selected template -->
      <div v-if="widget" class="u-flex-child">
        <template-detail :widget="widget" :is-fetching="isFetching" />
      </div>

      <!-- Template list -->
      <div v-else class="u-flex-child container-list">
        <!-- Search box -->
        <search
          v-if="showSearch"
          id="template-search"
          v-model="query"
          class="u-mt-medium u-mb-xxx-large"
        />

        <div class="container-cards container-cards-3">
          <template-card v-if="showCreateDataBlendFirst" :template="newWidget" link="/widgets/editor" />

          <template-card
            v-for="widget in widgets"
            :key="widget.guid"
            :template="widget"
            :link="'/templates/' + widget.guid"
            :click="selectTemplate"
          />

          <template-card v-if="showCreateDataBlend" :template="newWidget" link="/widgets/editor" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .wt-header{
    width: 100%;
  }

  .wt-menu {
    min-width: 250px;
    padding-right: 60px;
  }

  .wt-menu h5 {
    margin-bottom: var(--spacing-large);
    padding-left: var(--spacing-small);
    padding-right: 60px;
  }

  .wt-menu ul {
    margin: 0;
    padding: 0;
  }

  .wt-menu li {
    list-style: none;
  }
</style>

<script>
import { EventBroker, escapeRegex, activeWidgetMixin } from '@qlik-trial/automations-ui'
import TemplateDetail from './TemplateDetail'
import TemplateCard from './TemplateCard'
import { mapGetters } from 'vuex'

export default {
  components: { TemplateDetail, TemplateCard },
  mixins: [activeWidgetMixin],
  props: ['header', 'folder', 'selectedTemplateGuid'],

  data() {
    return {
      query: '',
      page: 0,
      hasMorePages: false,
      filteredWidgets: [],
      folderId: null,
      isFetching: false,
    }
  },

  computed: {
    ...mapGetters([
      'providerSettings',
      'isSuperAdmin',
      'isPowerAdmin',
      'isAdminProvider',
    ]),

    allFolders() {
      return this.flattenFolders(this.folder)
    },

    allWidgets() {
      return this.flattenWidgets(this.folder)
    },

    showSearch() {
      return this.allWidgets.length > 10 || this.allFolders.length > 1
    },

    widgets() {
      return this.allWidgets
        .filter(widget => {
          if (this.query) {
            return !widget.name.search(this.regex)
          }

          if (this.folderId && widget.pivot.folder_id !== this.folderId) {
            return false
          }

          return true
        })
      // Remove duplicate widgets
        .filter((v, i, a) => a.findIndex(w => w.guid === v.guid) === i)
    },

    showCreateDataBlend() {
      if (this.showCreateDataBlendFirst) {
        return false
      }

      return this.isSuperAdmin || this.isPowerAdmin || this.isAdminProvider || this.providerSettings.datablends.showAddDataBlend
    },

    showCreateDataBlendFirst() {
      return window.provider.id === 1
    },

    regex() {
      return new RegExp('.*' + escapeRegex(this.query).replace(' ', '.*') + '.*', 'i')
    },

    newWidget() {
      return {
        name: 'Start from blank',
        icon: '/img/plus_lightgrey.png',
      }
    },
  },

  watch: {
    widget: function (widget) {
      const breadcrumbs = [
        { click: () => this.setFolder(null), name: 'Integrations' },
      ]

      if (widget) {
        breadcrumbs.push({ name: widget.name })
      }

      EventBroker.publish('SET_BREADCRUMBS', breadcrumbs)
    },
  },

  created() {
    window.onpopstate = this.onPopState
    const selectedTemplateGuid = this.selectedTemplateGuid ? this.selectedTemplateGuid : this.$root.hash.startsWith('T-') ? this.$root.hash.substr(2) : null

    if (selectedTemplateGuid) {
      if (selectedTemplateGuid.length === 36) {
        this.selectTemplateByGuid(selectedTemplateGuid, true)

        return
      }
    }

    this.folderId = (this.allFolders.find(folder => folder.slug === this.$root.hash) || {}).id || null
  },

  methods: {
    onPopState() {
      const templateGuid = window.location.href.substring(window.location.href.lastIndexOf('/') + 1, window.location.href.length)

      if (templateGuid.length === 36) {
        this.selectTemplateByGuid(templateGuid, true)
      } else {
        this.setFolder(null, true)
      }
    },

    setFolder(id, noPush = false) {
      this.$store.commit('activeWidget', null)
      this.folderId = id

      if (noPush) {
        return
      }

      if (window.history && window.history.pushState) {
        const folder = this.allFolders.find(folder => folder.id === id)
        const hash = folder
          ? folder.slug
          : ''
        window.history.pushState(null, null, `/templates#${hash}`)
      } else {
        window.location.href = '#'
      }
    },

    selectTemplateByGuid(guid, noPush = false) {
      const widget = this.allWidgets.find(w => w.guid === guid)

      if (widget) {
        this.selectTemplate(widget, noPush)
        this.folderId = (this.allFolders.find(folder => folder.widgets.some(w => w.guid === widget.guid)) || {}).id
      }
    },

    selectTemplate(widget, noPush = false) {
      if (!noPush) {
        if (window.history && window.history.pushState) {
          window.history.pushState(null, null, '/templates/' + widget.guid)
        } else {
          window.location.href = '#T-' + widget.guid
        }
      }

      this.isFetching = true
      this.$store.dispatch('getTemplate', widget.guid)
        .then(fullWidget => {
          Object.assign(widget, fullWidget)
        })
        .finally(() => this.isFetching = false)

      this.$store.commit('activeWidget', widget)
    },

    flattenFolders(folder) {
      const folders = [folder]

      if (folder.folders) {
        folder.folders.forEach(innerFolder =>
          folders.push(...this.flattenFolders(innerFolder)),
        )
      }

      return folders
    },

    flattenWidgets(folder) {
      const widgets = folder.widgets

      if (folder.folders) {
        folder.folders.forEach(innerFolder =>
          widgets.push(...this.flattenWidgets(innerFolder)),
        )
      }

      return widgets
    },
  },
}
</script>
<style lang="scss">
  .group-breadcrumbs {
    margin-bottom: 60px;
    margin-top: 15px;
  }

  .group-breadcrumbs a {
    color: #b1c2d6;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.1;
  }

  .group-breadcrumbs a:hover {
    color: darken(#b1c2d6, 50%);
  }

  .group-breadcrumbs a:not(:last-child)::after {
    content: ' > ';
    margin: 5px;
  }

  .level2 {
    margin-left: 25px;
    margin-top: 15px;
  }

  .level2 ul li:not(:last-child) {
    margin-bottom: 10px;
  }

  .container-categories img {
    height: 25px;
    margin-right: 5px;
  }
</style>
