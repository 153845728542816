<!-- Use this component when you need a form element in a blade file and hence cannot use v-model, but need to
use value instead. -->
<template>
  <component
    :is="component"
    v-model="internalValue"
    :name="name"
  />
</template>

<script>
export default {
  props: {
    value: [String, Number, Boolean, Object],
    component: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      internalValue: this.value,
    }
  },
}
</script>
