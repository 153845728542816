class Attachment {
  file
  name
  state = 'pending'
  _error
  _url
  _data

  constructor(file) {
    this.file = file
    this.name = this.file.name
  }

  set url(url) {
    this._url = url
    this.state = 'uploaded'
  }

  get url() {
    return this._url
  }

  set error(error) {
    this._error = error
    this.state = 'failed'
  }

  get error() {
    return this._error
  }

  set data(data) {
    this._data = data
    this.state = 'transformed'
  }

  get data() {
    return this._data
  }

  get type() {
    return this.file.type
  }

  get size() {
    return this.file.size / 1000.0
  }
}

export default Attachment
