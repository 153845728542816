<template>
  <div>
    <!-- Search box -->
    <div class="u-flex-row u-align-center u-mt-x-small">
      <div v-if="showSearch">
        <search v-model="query" placeholder="Search" />
      </div>
    </div>

    <!-- table / list switcher -->
    <div class="u-mt-medium u-flex-row u-space-between u-align-end">
      <tags-bar v-if="showTags" />

      <div v-else>
        &nbsp;<!-- justify-content: space-between won't work without this div -->
      </div>

      <div class="u-flex-row u-align-center">
        <div v-if="providerSettings.datablends.showIndexToggleViewButton" class="u-ml-auto u-mt-xx-small">
          <i
            class="far fa-table fa-fw u-cursor-clickable"
            :class="{'we-list-active': !isList }"
            aria-hidden="true"
            @click="isList = false"
          /> &nbsp;/&nbsp;

          <i
            class="far fa-list-ul fa-fw u-cursor-clickable"
            :class="{'we-list-active': isList }"
            aria-hidden="true"
            @click="isList = true"
          />
        </div>

        <a href="/templates" class="btn btn--primary u-ml-x-large">
          Add new {{ __('blend') }}
        </a>
      </div>
    </div>

    <!-- Widgets -->
    <div v-if="isList" class="container-widgets u-mt-medium">
      <widget-list-item
        v-for="widget in widgets"
        :key="widget.guid"
        class="u-mb-small"
        :widget="widget"
      />

      <widget-list-item v-if="showStartFromBlank" :widget="newWidget" />
    </div>

    <div v-else class="panel container-medium u-mt-medium overflow">
      <table class="table td-10 th-10">
        <thead>
          <tr>
            <th style="min-width: 90px" />
            <th style="width: 99%">
              <div class="u-flex-row">
                <div class="u-cursor-clickable u-flex-child" @click="orderBy('name')">
                  {{ __('blend') }} name
                  <i v-if="orderByColumn === 'name' && orderByDirection === 'asc'" class="fas fa-sort-up" aria-hidden="true" />
                  <i v-if="orderByColumn === 'name' && orderByDirection === 'desc'" class="fas fa-sort-down" aria-hidden="true" />
                </div>
                <div
                  v-if="!isSimpleMode"
                  class="u-cursor-clickable"
                  style="margin-right: 5px"
                  @click="orderBy('run_mode')"
                >
                  <i class="far fa-fw fa-calendar-day" aria-hidden="true" />
                  <i v-if="orderByColumn === 'run_mode' && orderByDirection === 'asc'" class="fas fa-sort-up" aria-hidden="true" />
                  <i v-if="orderByColumn === 'run_mode' && orderByDirection === 'desc'" class="fas fa-sort-down" aria-hidden="true" />
                </div>
              </div>
            </th>
            <th style="min-width: 150px" class="u-cursor-clickable" @click="orderBy('created_at')">
              Created At
              <i v-if="orderByColumn === 'created_at' && orderByDirection === 'asc'" class="fas fa-sort-up" aria-hidden="true" />
              <i v-if="orderByColumn === 'created_at' && orderByDirection === 'desc'" class="fas fa-sort-down" aria-hidden="true" />
            </th>
            <th style="min-width: 150px" class="u-cursor-clickable" @click="orderBy('last_run_at')">
              Last Run
              <i v-if="orderByColumn === 'last_run_at' && orderByDirection === 'asc'" class="fas fa-sort-up" aria-hidden="true" />
              <i v-if="orderByColumn === 'last_run_at' && orderByDirection === 'desc'" class="fas fa-sort-down" aria-hidden="true" />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="loading">
            <td colspan="4">
              <i class="fas fa-spinner fa-spin fa-fw" /> Fetching data ...
            </td>
          </tr>

          <tr v-else-if="widgets.length === 0">
            <td colspan="4">
              No {{ __('blend', true) }} found
            </td>
          </tr>

          <tr
            v-for="widget in widgets"
            v-else
            :key="widget.guid"
            class="widgets-index-list-item"
          >
            <td>
              <a :href="`/widgets/${widget.guid}`" class="semantic-link">
                <img :src="widget.icon || '/img/default.svg'">
                <img v-if="widget.icon3" :src="widget.icon3">
              </a>
            </td>
            <td class="we-row-item">
              <a :href="`/widgets/${widget.guid}`" class="semantic-link">
                <div class="u-flex-row u-space-between" style="max-width: 688px;padding: 10px">
                  <div class="text-overflow u-mr-xx-small">
                    <h6 class="u-mv-xxx-small">
                      {{ widget.name }}
                    </h6>
                  </div>
                  <widget-icons v-if="!isSimpleMode" :widget="widget" class="text-nowrap" />
                </div>
              </a>
            </td>
            <td class="text-muted">
              <a :href="`/widgets/${widget.guid}`" class="semantic-link">
                <date :value="widget.created_at" />
              </a>
            </td>
            <td class="text-muted">
              <a :href="`/widgets/${widget.guid}`" class="semantic-link">
                <date :value="widget.last_run_at" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <pagination
        v-if="pagination.last_page !== 1"
        v-model="pagination"
        @input="fetch"
      />
    </div>

    <div class="clearfix" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { axios, getSocketIOConnection } from '@qlik-trial/automations-ui'
import TagsBar from '../TagsBar'
import { mapGetters } from 'vuex'

export default {
  localStorage: {
    'widgets-index-is-list': {
      type: Boolean,
    },
  },

  components: {
    TagsBar,
  },

  data() {
    return {
      isList: null,
      loading: true,
      widgets: [],
      pagination: { per_page: 12 },
      query: this.$localStorage.get('widgets-index-query', ''),
      activeWidget: null,
      deleteError: null,
      orderByColumn: this.$localStorage.get('widgets-index-order-by-column', null),
      orderByDirection: this.$localStorage.get('widgets-index-order-by-direction', 'asc'),
      showSearch: this.$localStorage.get('widgets-index-query', '').length > 0,
    }
  },

  computed: {
    ...mapGetters([
      'providerSettings',
    ]),

    newWidget() {
      return {
        guid: 'editor',
        name: 'Start from blank',
        icon: '/img/plus_lightgrey.png',
      }
    },

    isSimpleMode() {
      return this.providerSettings.datablends.simpleMode
    },

    showTags() {
      return ! this.isSimpleMode
    },

    activeTagIds() {
      return this.$store.getters.getActiveTagIds
    },

    showStartFromBlank() {
      return this.providerSettings.datablends.showAddDataBlend && this.widgets.length === 0
    },
  },

  watch: {
    query: {
      immediate: true,
      handler: function (value) {
        if (value !== null && value !== undefined) {
          this.$localStorage.set('widgets-index-query', value)
        } else {
          this.$localStorage.set('widgets-index-query', '')
        }
        this.pagination.current_page = 1
        this.debouncedFetch()
      },
    },

    isList: function (value) {
      this.$localStorage.set('widgets-index-is-list', value)
    },

    activeTagIds: function (value, oldValue) {
      if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
        this.pagination.current_page = 1
        this.fetch()
      }
    },
  },

  created() {
    this.isList = this.providerSettings.datablends.showIndexToggleViewButton
      ? this.$localStorage.get('widgets-index-is-list', this.providerSettings.datablends.defaultIndexView === 'list')
      : this.providerSettings.datablends.defaultIndexView

    this.listenForUpdates()
  },

  methods: {
    updateWidget(widget) {
      const existingWidget = this.widgets.find(w => w.guid === widget.guid)

      if (!existingWidget) {
        return
      }

      Object.assign(existingWidget, widget)
    },

    deleteWidget(widget) {
      const existingWidgetIndex = this.widgets.findIndex(w => w.guid === widget.guid)

      if (existingWidgetIndex !== -1) {
        this.widgets.splice(existingWidgetIndex, 1)
      }
    },

    orderBy(column) {
      this.orderByDirection = this.orderByColumn === column && this.orderByDirection === 'desc' ? 'asc' : 'desc'
      this.orderByColumn = column
      this.$localStorage.set('widgets-index-order-by-column', this.orderByColumn)
      this.$localStorage.set('widgets-index-order-by-direction', this.orderByDirection)

      this.fetch()
    },

    debouncedFetch: debounce(function () {
      this.fetch()
    }, 200),

    fetch() {
      axios.get('/widgets', {
        params: {
          query: this.query,
          type: 'widgets',
          orderByColumn: this.orderByColumn,
          orderByDirection: this.orderByColumn ? this.orderByDirection : null,
          page: this.pagination.current_page,
          tags: this.showTags ? this.activeTagIds : null,
        },
      }).then(response => {
        if (response.data.total > 12) {
          this.showSearch = true
        }
        this.loading = false
        this.widgets = response.data.data
        this.pagination = {
          total: response.data.total,
          per_page: response.data.per_page,
          current_page: response.data.current_page,
          last_page: response.data.last_page,
          from: response.data.from,
          to: response.data.to,
        }
      })
    },

    listenForUpdates() {
      this.getAccountWSChannel().then(channel => {
        channel.listen('automation.updated', this.handleAutomationUpdated.bind(this))
        channel.listen('automation.deleted', this.handleAutomationDeleted.bind(this))
        channel.listen('automation.created', this.handleAutomationCreated.bind(this))
      })
    },

    getAccountWSChannel() {
      const accountGuid = this.$store.getters.getUser.account.guid

      return getSocketIOConnection().private(`accounts.${accountGuid}`)
    },

    handleAutomationUpdated(payload) {
      delete payload.socket
      const index = this.widgets.findIndex(automation => automation.guid === payload.guid)
      this.$set(this.widgets, index, payload)
    },

    handleAutomationDeleted(payload) {
      const index = this.widgets.findIndex(automation => automation.guid === payload.guid)
      this.widgets.splice(index, 1)
    },

    handleAutomationCreated(payload) {
      delete payload.socket
      this.widgets.unshift(payload)
    },
  },
}
</script>

<style lang="scss">
  .we-list-active {
    color: var(--color-link);
  }

  .widgets-index-list-item {
    cursor: pointer;
  }

  .widgets-index-list-item img {
    max-height: 24px;
    max-width: 24px;
  }

  .widgets-index-list-item img + img {
    margin-left: 5px
  }

  .widgets-index-list-item td {
    vertical-align: middle !important;
  }

  .widgets-index-list-item td a {
    display: block;
    min-height: 100%;
    width: 100%;
  }

  .widgets-index-list-item:hover {
    background-color: var(--color-background--accent)
  }

  .we-row-item{
    padding: 0!important;
  }

  .we-row-item:not(:hover) .fa-tag{
    display: none !important;
  }
</style>
