import Vue from 'vue'
import { axios } from '@qlik-trial/automations-ui'

const NO_TAG_ID = 0

const state = {
  tags: {},
  noTag: {
    active: false,
    color: 'FFFFFF',
    id: NO_TAG_ID,
    name: null,
  },
}

const getters = {
  getTags: state => {
    let tags = Object.values(state.tags)
    if (tags.length) {
      tags = [
        ...tags,
        state.noTag,
      ]
    }

    return tags
  },

  getActiveTags: (state, getters) => {
    return getters.getTags.filter(tag => tag.active)
  },

  getActiveTagIds: (state, getters) => {
    return getters.getActiveTags.map(tag => tag.id)
  },
}

const actions = {
  deleteTag({ commit }, tag) {
    if (tag.id === 'new') {
      return Promise.resolve(commit('deleteTag', tag))
    }

    return axios.delete('/tags/' + tag.id)
      .then(() => commit('deleteTag', tag))
  },

  saveTag({ commit }, tag) {
    return axios({
      method: tag.id === 'new' ? 'POST' : 'PUT',
      url: '/tags' + (tag.id !== 'new' ? '/' + tag.id : ''),
      data: tag,
    }).then(response => {
      if (tag.id === 'new') {
        commit('deleteTag', tag)
      }

      commit('setTag', response.data)

      return response.data
    })
  },

  associateTags({}, { tags, resource }) {
    return axios.post(resource + '/tags', {
      associate: tags.map(tag => tag.id),
    })
      .then(response => response.data)
  },

  dissociateTags({}, { tags, resource }) {
    return axios.post(resource + '/tags', {
      dissociate: tags.map(tag => tag.id),
    })
      .then(response => response.data)
  },
}

const mutations = {
  initActiveTags(state, { user }) {
    const currentActiveTags = JSON.parse(localStorage.getItem('active-tags') || '{}')
    const activeTagIds = currentActiveTags && currentActiveTags[user.id] || []

    Object.values(state.tags).forEach(tag => {
      Vue.set(tag, 'active', activeTagIds.includes(tag.id))
    })

    Vue.set(state.noTag, 'active', activeTagIds.includes(state.noTag.id))
  },

  setTags(state, tags) {
    Vue.set(state, 'tags', tags)
  },

  setTag(state, tag) {
    if (!('active' in tag)) {
      tag.active = false
    }

    Vue.set(state.tags, tag.id, tag)
  },

  deleteTag(state, tag) {
    Vue.delete(state.tags, tag.id)
  },

  toggleTag(state, { tag, user }) {
    if (tag.id === NO_TAG_ID) {
      Vue.set(state.noTag, 'active', !state.noTag.active)
    } else {
      Vue.set(state.tags[tag.id], 'active', !tag.active)
    }

    const userId = user.id
    const currentActiveTags = JSON.parse(localStorage.getItem('active-tags') || '{}')
    currentActiveTags[userId] = Object.values(state.tags).filter(tag => tag.active).map(tag => tag.id)

    if (tag.id === NO_TAG_ID && state.noTag.active) {
      currentActiveTags[userId] = [...currentActiveTags[userId], NO_TAG_ID]
    }
    localStorage.setItem('active-tags', JSON.stringify(currentActiveTags))
  },
}


export default {
  state,
  actions,
  mutations,
  getters,
}
