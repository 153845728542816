<template>
  <div class="simple-bar-chart">
    <div v-for="(bar, index) in data">
      <bar :width="getBarWidth(bar.value)" :index="index" />
      <div class="u-flex-row u-align-baseline u-mt-xx-small">
        <h5 class="u-mr-xx-small u-mv-none" v-text="getFormattedValue(bar.value)" />
        <label class="text-muted u-mb-none" v-text="bar.label" />
      </div>
    </div>
  </div>
</template>

<script>
import Bar from './Bar'

export default {
  components: { Bar },

  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  computed: {
    max() {
      return Math.max(...this.data.map(item => item.value))
    },
  },

  methods: {
    getBarWidth(value) {
      if (!value) {
        return 0
      }

      return 100.0 * value / this.max
    },

    getFormattedValue(value) {
      return !isNaN(value) ? (value).toLocaleString('en') : 0
    },
  },
}
</script>
